import Step from '@/components/global/Step/Step.vue'
// import {from} from 'core-js/fn/array';
import ProductItem from './components/ProductItem.vue'
import MessageModal from './../settlement/components/messageModal'
import {
  // orderWeek,
  // 购物车查询
  orderCar,
  // 统仓联系人
  orderAddress,
  // 分销商 分销地址
  // showAnother,
  // 收货地址切换带出联系人
  //。。。。
  // 获取城市
  cityChange,
  // 获取城镇 区
  townChange,
  // 获取城镇区下一级
  getQu,
  // 分销商切换 获取新分销商地址
  getDISAdd,
  // 分销商地址切换请求联系人
  getPhone,
  // 要求到货周
  getWeek,
  // 商品列表
  // goodsList,
  // 获取商品列表库存数
  getNumber,
  // 购物车提交
  // cartSubmit,
  // 查看预占用额度明细
  quotaDeatail,
  numberChangeApi, //数量改变调接口
  againOrderSubmit, //再来一单提交,
  moneyWeek,
  // 充值
  saveMoney,
  // 充值记录
  saveHisetory,
  SalesShopInfoList,
  // 服务列表
  serviceInformation,
} from './api';
import {
  saveMoneyType
} from "@/common/constant";
import { checkShipTo, getAdressapi } from "@/views/settlement/api";
import moment from "moment";
import { getChannelStockLIST } from "@/views/product/api";
import { ctsApi, fxZsyhGetDeliveryMethod } from "@/views/channel/api";
import { getRetailPrice } from "@/views/stockCenter/adjustmentApply/components/api";
import { findMoneyBuyBegin } from "@/views/specialZone/api";
import { findList, findListLabel, saveInfo, saveInfoLabel } from "@/views/userOperations/IntendedUserEntry/api";
import addUserSource from "@/views/userOperations/IntendedUserEntry/components/addUserSource";
import addUserTips from "@/views/userOperations/IntendedUserEntry/components/addUserTips";
import { getUserList } from "@/views/userOperations/IntentionList/api";
import { checkRetailL } from "@/views/channelInput/saleNew/api";
import addressModal from "@/views/settlement/components/addressModal";

export default {
  name: "Home",
  components: {
    Step,
    ProductItem,
    MessageModal,addUserSource,addUserTips,addressModal
  },
  data() {
    return {
      visibleAddress:false,
      newTown:'',
      isLoading: false,
      isYuMoney: true,
      modelShow: false,
      // 查看占用明细列表
      columns: [{
          title: '销售组织',
          dataIndex: 'orgName',
          key: 'orgName',
          width:100
        },
        {
          title: '物料组',
          dataIndex: 'matklName',
          key: 'amatklNamege',
          width:100
        },
        {
          title: '订单编码',
          dataIndex: 'orderCode',
          key: 'orderCode',
          width:150
        },
        {
          title: '订单类型',
          dataIndex: 'orderType',
          key: 'orderType',
          width:100
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          key: 'status',
          width:100
        },
        {
          title: '商品型号',
          dataIndex: 'zzprdmodel',
          key: 'zzprdmodel',
          width:100
        },
        {
          title: '商品数量',
          dataIndex: 'qty',
          key: 'qty',
          width:100
        },
        {
          title: '付款状态',
          dataIndex: 'fkStatus',
          key: 'fkStatus',
          width:100
        }, {
          title: '订单金额',
          dataIndex: 'orderAmt',
          key: 'orderAmt',
          width:100
        }, {
          title: '预占用金额',
          dataIndex: 'waitBalance',
          key: 'waitBalance',
          width:120
        }, {
          title: '要求到货周次',
          dataIndex: 'weekName',
          key: 'weekName',
          width: '150'
        },
        {
          title: '下单日期',
          dataIndex: 'createdDate',
          key: 'createdDate',
          width:150
        },
      ],
      // 查看占用明细列表
      data: [],
      bankChecked: true,
      visible: false,
      pageLoadFlag: false,
      pageLoadFlagTable: false,
      steps: ['选择商品', '确认订单信息', '提交订单'],
      currentStep: 2,
      options: [],
      form: {
        name: '',
        billName: undefined,
        address: undefined,
        agency: undefined,
        askWeekend: undefined,
        distributor: undefined,
        distributorAddress: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
      },
      rules: {
        name: [{
            required: true,
            message: '请输入联系人',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 5,
            message: 'Length should be 3 to 5',
            trigger: 'blur'
          }
        ],
        phone: [{
            required: true,
            message: '请输入联系方式',
            trigger: 'blur'
          },
          {
            min: 7,
            max: 11,
            message: '请输入正确的联系方式',
            trigger: 'blur'
          },
        ],
        billName: [{
          required: true,
          message: '请选择开票户头',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '请选择收货地址',
          trigger: 'change'
        }],
        agency: [{
          required: true,
          message: '请选择海信办事处',
          trigger: 'change'
        }],
        askWeekend: [{
          required: true,
          message: '请选择周',
          trigger: 'change'
        }],
        distributor: [{
          required: true,
          message: '请选择分销商',
          trigger: 'change'
        }],
        distributorAddress: [{
          required: true,
          message: '请选择分销商地址',
          trigger: 'change'
        }],
        date1: [{
          required: true,
          message: 'Please pick a date',
          trigger: 'change'
        }],
        type: [{
          type: 'array',
          required: true,
          message: 'Please select at least one activity type',
          trigger: 'change',
        }, ],
        resource: [{
          required: true,
          message: 'Please select activity resource',
          trigger: 'change'
        }, ],
      },
      // 联系人
      contacts: '',
      // 联系人手机号
      phoneCon: '',
      // 开票户头账号
      choseAccounts: [],
      // 是否显示其他开票户头
      // accountShow: false,
      // 开票户头
      invoice: '',
      // 开票户头id
      invoiceId: '',
      //是否允许提前发货
      allowAdvance: false,
      // 用户选择截止时间
      endTime: '',
      // 控制分销商及分销商地址是否显示
      distributors: false,
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: '',
      distrAdd: false,
      distrName: '',
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: '',
      // 供应商为海信时显示供应商为物料组
      suppilers: '股份青岛-电视',
      // 配送方式选择直配到用户
      userAdderss: false,
      // 用户详细地址
      userDetailAddress: '',
      // 用户省市区级联
      // 省市区三级联动
      provinceData: ['Zhejiang', 'Jiangsu'],
      cityData: {
        Zhejiang: ['Hangzhou', 'Ningbo', 'Wenzhou'],
        Jiangsu: ['Nanjing', 'Suzhou', 'Zhenjiang'],
      },
      cities: ['Hangzhou', 'Ningbo', 'Wenzhou'],
      secondCity: '',
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: '',
      // 收货地址id
      receiveID: '',
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      houseAddress: '收货地址',
      // 统仓联系人禁止修改
      TCInt: false,
      // 配送方式ID
      // sendId: '',
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: '',
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      // proObj: {},
      // 开票户头
      billToDTOs: [],
      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: '',
        DISADD: ''
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: '',
        orgId: '',
        matklId: ''
      },

      // 要求到货周
      arriveOptions: [],
      selected: '',
      GoodsList: [],
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: '',
      // 上个页面传过来的参数
      RouterParams: {
        carts: '1068455',
        versions: '',
        orgAndGroup: '176-20160119008',
        purchaseType: 2
      },
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: '',
      orderCartIds: {},
      // 配送方式
      trans: '',
      hisenseOff: false,
      officehis: [],
      officeCode: '',
      receivehis: '',
      officeHis: false,
      orgCode: '',
      matklId: '',
      orgId: '',
      orgtitle: '',
      // 下拉框是否默认开启
      openoffice: true,
      addressopen: true,
      // 开票户头下拉
      receiveBill: false,
      addressopenbill: true,
      receivebillss: '',
      // 面包屑
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/order/cis",
          // name: "order",
          title: "订单中心"
        },
        {
          path: "/confirm/orderAgain",
          name: "confirmagain",
          title: "订单结算"
        }
      ],
      // 是否使用返利
      tradeType: true,
      //返利按钮是否选中
      isTradeChecked: true,
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 500
      },
      rebate: '0',
      TotalPaymentss: {},
      realData: {},
      dataAll: [],
      URLTO: '',
      saveMoneyValue: {},
      totalRebate: '',
      psfs: 0,
      saleShopList:[], // 销量所属门店
      receiveShop:'', // 销量所属门店名称
      salesShopInfoId:'',  // 销量所属门店id
      saleCherkId:'', // saleCherkId 后端返回的之前单子选中的销量门店id
      serviceCherkId:'',
      fwlbId:[],
      // 服务列表数据
      FWlist:[],
      provinceCode: '',//省
      cityCode: '',//市
      districtCode: '',//区
      townCode: '',//县乡
      companyList:[],
      fetching:false,
      onluyOneMessage:false,
      mainMsg:'',
      orgMsg:'',
      addressHx:[],
      arrivalTimeStr:'',
      visibleaA: false,// 新建用户来源弹窗
      visibleTips: false,// 新建用户标签弹窗
      userSorceList: [],
      SorceIds: '',// 选中的用户来源
      userTipsList: [],
      TipsIds: [],// 选中的标签
      TipsNames: [],// 选中的标签
      SelectItem:[],
      isAddressSupport:'',
      sexId:1,
      sexoptions: [{id: 1, name: '男士'}, {id: 2, name: '女士'}], // 用户性别列表
      serviceCharge:0,
      orderServiceFeeItemDtoList:[],
      warehouseInfoId:'',
      fwOrgName:'' ,
      isShowLogisticsIcon:false,
      phone:'',
      text1:'',
      text2:'',
      haveDeliveryFee: false,
      transDefault:'',
      fhList:[
        {
          id:'1',
          name:'允许'
        },
        {
          id:'2',
          name:'不允许'
        }
      ],
      fhIndex:'1',// 提前发货 1允许 2不允许
      showSorce:false,
      sourceName:'',
      endOpen:false,
      startShowTime:'',
      isShowBtn:true

    };
  },

  mounted() {
    this.RouterParams = this.$route.query
    // 购物车查询
    this.carList()
    this.URLTO = saveMoneyType
    this.getuserSorceList()
    this.getuserTipList()
    const data1 = this.$getAlertInfoByCode('TS0248')
    const data2 = this.$getAlertInfoByCode('TS0249')
    this.text1 = data1.msgDesc.split('${phone}')
    this.text2 = data2.msgDesc
    this.phone = data1.remark
    // 如果是生态产品  不展示在线充值  充值记录
    if((this.$route.query.orgAndGroup && this.$route.query.orgAndGroup.indexOf('1161') !== -1) || (this.$route.query.orgId&&this.$route.query.orgId.indexOf('1161') !== -1)) {
      this.isShowBtn = false
    }
  },
  watch:{
    GoodsList: {
      handler:function(val,old) {
        if(this.GoodsList.length > 0) {
          this.GoodsList.forEach(el => {
            checkRetailL({id:el.productId}).then(res => {
              if(res.data && res.data.code == 0){
                const {type, money, bei, min, max, haveRetailL } = res.data;// 0不限制  1限制
                el.min = min;
                el.bei = bei;
                el.type = type;
                el.max = max;
                el.salePrice = money?this.$util.getFloat(Number(money),2):''
                el.retailPrice = money?this.$util.getFloat(Number(money),2):''
                el.haveRetailL = haveRetailL;
              }
            })
          })
        }
      },
      // 深度观察监听
      deep: true
    },
  },
  methods: {
    changePhoneNum() {
      this.phoneCon =  this.phoneCon.replace(/\D/g, '')
      if( this.phoneCon.trim().length == 11&&this.salesShopInfoId&&(this.trans=='502004'||this.trans=='502008')){
        let data = {
          phone: this.phoneCon,
          shopInfoId:this.salesShopInfoId
        }
        this.TipsNames = []
        this.TipsIds = []
        getUserList(data).then(res=>{
          if(res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            if(!this.contacts){
              this.contacts =  res.data.list[0].userName
            }
            this.SorceIds =  res.data.list[0].sourceId? res.data.list[0].sourceId:5007684
            this.sourceName =  res.data.list[0].sourceName?res.data.list[0].sourceName:'自然到访'
            this.showSorce = (this.SorceIds ==  res.data.list[0].sourceId)?true:false
            this.sexId = res.data.list[0].genderName=='男' ? 1 : (res.data.list[0].genderName=='女'? 2 : 1 )
            if(this.userTipsList.length>0){
              this.TipsNames = []
              this.TipsIds = []
              this.userTipsList.forEach(item=>{
                item.checked = false

              })
            }
            if( res.data.list[0].userLableList.length>0 && this.userTipsList.length > 0){
              this.userTipsList.forEach(item=>{
                res.data.list[0].userLableList.forEach(twoItem=>{
                  if(item.id == twoItem.labelId) {
                    item.checked = true
                    this.TipsIds.push(twoItem.labelId)
                    this.TipsNames.push(twoItem.labelName);
                  }
                })
              })
            }else {
              this.sexId = 1
              this.TipsIds=[]
              this.TipsNames=[]
              this.$nextTick(()=>{
                if(this.userTipsList.length>0){
                  this.userTipsList.forEach(item=>{
                    item.checked = false
                    if(item.id  == 5007817){
                      item.checked = true
                      this.TipsIds.push(item.id)
                      this.TipsNames.push(item.label);
                    }
                  })
                }
              })
              this.SorceIds = 5007684
              this.sourceName =  '自然到访'
              this.showSorce = false
            }

          } else {
            this.sexId = 1
            this.addressHx = []
            this.showSorce = false
            this.TipsIds=[]
            this.TipsNames=[]
            this.$nextTick(()=>{
              if(this.userTipsList.length>0){
                this.userTipsList.forEach(item=>{
                  item.checked = false
                  if(item.id  == 5007817){
                    item.checked = true
                    this.TipsIds.push(item.id)
                    this.TipsNames.push(item.label);
                  }
                })
              }
            })
            this.SorceIds = 5007684
            this.sourceName =  '自然到访'
            this.showSorce = false
          }
        })
      }

    },
    changeFhBtn(item){
      this.fhIndex = item.id
    },
    changeSEX(e) {
      this.sexId = e.target.value;
    },
    changePrice(item){
      this.$forceUpdate()
    },
    openSource(){
      this.visibleaA = true
    },
    openTips(){
      this.visibleTips = true
    },
    // 获取用户来源
    getuserSorceList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findList(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList = res.data.data;
          if(this.userSorceList.length > 0) {
            this.userSorceList.forEach(item => {
              item.id = (item.id).toString();
              item.ischerk = false;
            });
          }

          this.pageLoadFlag = false;
        }
        this.pageLoadFlag = false;

      });
    },
    // 获取用户标签
    getuserTipList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findListLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList = res.data.data;
          if (this.userTipsList.length > 0) {
            this.userTipsList.forEach(item => {
              item.checked = false;
            });
          }
          this.pageLoadFlag = false;

        }
        this.pageLoadFlag = false;
      });
    },
    // 点击
    changeSorceBtn(item) {
      if(this.SorceIds == item.id) {
        this.SorceIds = ''
        return
      }
      this.SorceIds = item.id;
      this.$forceUpdate();
    },
    changeBtnnew(item){
      this.SorceIds = item;
    },

    changeTipsBtn(item) {
      if (!item.checked) {
        if (this.TipsIds.length >= 8) {
          this.$message.warning("用户标签选择不能超过8个");
          return;
        }
      }
      item.checked = !item.checked;
      this.$forceUpdate();
      this.TipsIds = [];
      this.TipsNames = [];
      this.userTipsList.forEach(items => {
        if (items.checked == true) {
          this.TipsIds.push(items.id);
          this.TipsNames.push(items.label);
        }
      });
      this.$forceUpdate();
    },
    // 点击弹窗取消和确定的时候
    chengeOK(item,name) {
      this.TipsIds = [];
      this.TipsNames= [];
      this.TipsIds = item;
      this.TipsNames = name
      this.userTipsList.forEach(items=>{
        items.checked = false
        item.forEach(itemB=>{
          if(items.id == itemB) {
            items.checked = true
          }
        })
      })
    },
    // 用户来源点击确定 调接口 增加数据 成功的话赋值
    getReason(value,value2) {
      if (!value) {
        this.SorceIds = value2
        return;
      }
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        source: value,
        remark: ""
      };
      this.pageLoadFlag = true;
      saveInfo(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList.push(
            {
              id: res.data.data,
              source: value
            }
          );
          this.SorceIds = "";
          this.SorceIds = this.userSorceList[this.userSorceList.length - 1].id;
          this.$forceUpdate();
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }

      });
    },
    getReasonTip(value, value1) {

      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        label: value,
        remark: value1
      };
      saveInfoLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList.push(
            {
              id: res.data.data,
              label: value,
              checked: true
            }
          );
          this.TipsIds.push(res.data.data);
          this.pageLoadFlag = false;

        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },
    getPS() {
      const itema = [{
        orgId: this.GoodsList[0].orgId,
        matklCode: this.GoodsList[0].materialGroup,
        productIds: this.GoodsList.map(el => {
          return {
            id: el.productId
          }
        })
      }]
      let data = {
        orderType:this.RouterParams.purchaseType==1?5:1,
        // serviceCode: 'cisFxZsyhGetDeliveryMethod',
        code:this.$store.state.user.userInfo.customer.cisCode,
        fxCustCisCode: this.$store.state.user.userInfo.customer.cisCode,
        dlCustCisCode:this.$store.state.user.userInfo.customer.cisCode,
        items: itema,
      }
      fxZsyhGetDeliveryMethod(data).then(res=>{
        if(res.data.code == 0) {
          this.options = res.data.data.items
          this.isShowLogisticsIcon = this.options.some(el => ['04', '08'].includes(el.code))
          // 获取接口中check == true 默认选中的下标
          this.transDefault = this.options.findIndex(el => el.check == true)>-1?this.options.findIndex(el => el.check == true):''
          // 赋值配送方式
          this.trans = this.options[this.transDefault].id
          // 是否展示服务费 根据配送方式中字段判断 // 0否，1是
          this.haveDeliveryFee = this.options[this.transDefault].haveDeliveryFee == 1? true : false
          this.$forceUpdate()
        }
      })
    },
    // 控制可选时间范围
    disabledDate(current) {
      let nowDate = moment().format("YYYY-MM-DD")
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(nowDate) || current > moment(nowDate + ' 23:59:59').add(29, 'd')
    },
    disabledDateS(current) {
      // let nowDate = moment().format("YYYY-MM-DD")
      // 目前这个值是写死的 后期需要对接口
      let three = moment(this.startShowTime)
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(this.startShowTime) || current > three.add(29, 'd')
    },
    arrivalTimeFocus(){
      if( !this.arrivalTimeStr && this.haveDeliveryFee){
        this.$message.warning('请先填选相关信息')
        return
      }else {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(){
      if(this.arrivalTimeStr){
        this.endOpen = true;
        return
      }
    },
    // 期望送货日期时间选择
    arrivalTimeChange(value,dateString) {
      this.arrivalTimeStr = dateString;
      this.endOpen = false
    },
    blurAddress(){
      if(!this.districtCode) {
        this.userDetailAddress = ''
        return
      }
    },
    // 获取下拉地址
    getAdress(value) {
      if(!this.districtCode) {
        this.$message.warning('请先选择省市区县')
        this.$refs.address.blur()
        this.userDetailAddress = ''
        return
      }
      this.userDetailAddress = value;
      if(!this.userDetailAddress ){
        this.companyList = []
        return
      }
      if(this.userDetailAddress  && this.userDetailAddress.length < 4 && this.userDetailAddress.length > 0){
        this.companyList = []
        return
      }
      let data  = {
        searchstr: this.userDetailAddress,
        region:this.districtCode
      }
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data!==null && res.data.data.pois && res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList =[]
        }

        this.fetching = false;
      });

    },
    //查询数据
    search(vaule,e) {
    },
    async sumbitaaa() {
      this.isLoading = true
        this.orderSubmit()
        this.isLoading = false
        return

    },
    // 服务列表查询
    getService() {
      let data  = {}
      serviceInformation(data).then(res=>{
        this.FWlist = res.data

        if( this.serviceCherkId !== '') {
          this.FWlist.forEach(item=>{
            if(item.serviceTypeCode ==  this.serviceCherkId){
              this.fwlbId .push( item.serviceTypeCode)
            }
          })
        } else {
          this.fwlbId = []
        }
      }) 
    },
    // 销量所属门店查询
    getsaleShopList() {
      let data = {
        orgId: this.orgId,
        matklId: this.matklId
      }
      SalesShopInfoList(data).then(res=>{
        this.saleShopList = res.data.list
        if( this.saleCherkId !== '') {
          this.saleShopList.forEach(item=>{
         
            if(item.code ==  this.saleCherkId){
              this.receiveShop = item.name
              this.salesShopInfoId = item.code
            }
          })
        } else {
          this.receiveShop =res.data.list.length>0? res.data.list[0].name:'';
          this.salesShopInfoId = res.data.list.length>0?res.data.list[0].code:'';
        }
        if((this.trans=='502004' || this.trans == '502008') && this.haveDeliveryFee){
          this.getFWF()
        }

      })
    },

    // 更改销量所属于门店
    handleChangeshopList(value, e) {
      this.receiveShop = value;
      this.salesShopInfoId = e.key;
      this.arrivalTimeStr =  ''
      this.startShowTime =  ''
      this.changePhoneNum()
      if((this.trans=='502004' || this.trans == '502008') && this.haveDeliveryFee){
        this.getFWF()
      }
    },
    // 查询服务费
    getFWF(){
      if( !this.townCode ){
        this.$message.warning('请选择省市区县')
        return
      }
      // if( !this.warehouseInfoId ){
      //   this.$message.warning('请选择统仓地址')
      //   return
      // }
      // if( ! this.salesShopInfoId ){
      //   this.$message.warning('请选择销量所属门店')
      //   return
      // }
      this.arrivalTimeStr =  ''
      this.startShowTime =  ''
      let orderServiceFeeItemDtoList = []
      this.GoodsList.forEach(item=>{
        orderServiceFeeItemDtoList.push({
          "productId": item.productId,		//产品id
          "warehouseInfoId": "",			//仓库werks-lgort，例如2600-1532，dms代理商下单必传，其他情况传空
          "qty": item.quantity,
          zzprdmodel:item.productName,
          orgId:this.orgId
        })
      })
      let data = {
        "townCode":  this.townCode,
        "countyCode": this.districtCode,
        "cityCode":  this.cityCode,
        "provinceCode":  this.provinceCode ,
        "warehouseInfoId":  this.warehouseInfoId?this.warehouseInfoId:'',			//发货仓库id，信天翁代理商下单必填，信天翁分销商下单或者dms下单必须传空
        "shopId": '',			//门店id
        "documentSource":"1",			//dms下单传5，其他随便传个不是5的数就行
        "orderServiceFeeItemDtoList": orderServiceFeeItemDtoList,
        trans:this.trans
      }
      findMoneyBuyBegin(data).then(res => {
        // 获取送达时间
        if(res.data && res.data.data && res.data.data.timelinessDate){
          this.arrivalTimeStr =  res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
          this.startShowTime = res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
        } else{
          //  如果接口没返回数据 就默认当前日期加3天
          this.arrivalTimeStr =  this.$util.getDateSpecify(3)
          this.startShowTime = this.$util.getDateSpecify(3)
        }
        if(res.data.code == 0){
          this.serviceCharge = res.data.data.totalAmount
          this.orderServiceFeeItemDtoList = res.data.data.orderServiceFeeItemDtoList
          this.orderServiceFeeItemDtoList.forEach((itemFirst,indexFirst)=>{
            this.GoodsList.forEach((item,index)=>{
              // item.serviceMoney = 0
              if( itemFirst.productId == item.productId ){
                this.$nextTick(()=> {
                  this.$set(item,'serviceMoney',itemFirst.serviceFee)
                })
              }
            })
          })
        }
        if(res.data.code == 1 ){
          this.serviceCharge = 0
          this.GoodsList.forEach((item,index)=>{
            this.$nextTick(()=> {
              this.$set(item,'serviceMoney','0.00')
            })
          })
        }
      }).catch(err=>{
        //  如果接口没返回数据 就默认当前日期加3天
        this.arrivalTimeStr =  this.$util.getDateSpecify(3)
        this.startShowTime = this.$util.getDateSpecify(3)
      })
    },
    // 在线充值
    saveMoneyOnline() {
      saveMoney().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPay = document.getElementById("selfOrderPay");
          selfOrderPay.submit();
        })
      })
    },
    // 充值记录
    saveMoneyOnlineHis() {
      saveHisetory().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPayh = document.getElementById("selfOrderPayh");
          selfOrderPayh.submit();
        })
      })
    },
    //是否返利点击
    tradeOnChange(e) {
      this.isTradeChecked = e.target.checked;
    },
    moveVersion(id) { //移除政策
      this.goodsPrice = 0
      this.GoodsList.forEach(item => {
        if (item.productId == id) {
          item.policyId = ''
          this.goodsPrice = (Number(this.goodsPrice) + Number(item.price * item.quantity)).toFixed(2)
        }
      })
    },
    totalPriceGet(id, versionId) { //改变政策
      this.goodsPrice = 0
      this.GoodsList.forEach(item => {
        if (item.productId == id) {
          item.policyId = versionId
          this.goodsPrice = (Number(this.goodsPrice) + Number(item.price * item.quantity)).toFixed(2)
          // item.subTotal = Number(item.price*item.quantity).toFixed(2)
        }
      })
    },
    numberChange($event, id) {
      if((this.trans=='502004' || this.trans == '502008') && this.haveDeliveryFee){
        this.getFWF()
      }
      this.goodsNum = 0
      this.goodsPrice = 0
      this.goodsVolume = 0
      let versionIs = '';
      var rebate = 0;
      this.GoodsList.forEach(item => {
        this.goodsNum += item.quantity

        if (item.productId == id) {
          item.isChooseNum = $event
          item.quantity = $event
          this.goodsPrice = (Number(this.goodsPrice) + Number(item.price * item.quantity)).toFixed(2)
          this.goodsVolume = (Number(this.goodsVolume) + Number(item.loadVolume * item.quantity)).toFixed(3)
          item.subTotal = Number(item.price * item.quantity).toFixed(2)
          if (item.policyId !== null) {
            versionIs = item.policyId
          } else {
            versionIs = ''
          }
        }
        rebate += (item.rebate / 100) * item.subTotal;
      })
      this.totalRebate = rebate > this.realData.cartOrder.balanceDTO.rebate ? this.realData.cartOrder.balanceDTO.rebate : rebate;
      this.totalAdvanceMoney = ((this.goodsPrice * this.advancePayRate) / 100).toFixed(2)
      let obj = {
        products: id,
        versionIds: versionIs,
        productNumbers: $event,
        orgAndGroup: this.$route.query.orgAndGroup
      }
      numberChangeApi(obj).then(res => {
        this.TotalPayment.rebate = res.data
        this.rebate = res.data
      }).catch(err => {
        console.log(err);
      })
    },
    // 购物车查询
    carList() {
      this.pageLoadFlag = true
      let params = {
        id: this.$route.query.carts
      }
      orderCar(params).then(res => {
        this.pageLoadFlag = false;
        this.realData = res.data;
        // 组织编码 物料组编码
        this.matklId = res.data.cartOrder.matklId;
        this.orgId = res.data.cartOrder.orgId;
        // 销售门店id
        if(res.data.salesShopInfoId) {
          this.saleCherkId = res.data.salesShopInfoId
        }
        // 服务列表
        if(res.data.serviceTypeCodes) {
          this.serviceCherkId = res.data.serviceTypeCodes
        }
        
        for (var kes in res.data.cartOrder.orgRange) {
          this.orgCode = kes;
        }

        this.orderDis = res.data.cartOrder.customerAddressDTOs;
        this.receive = res.data.cartOrder.customerAddressDTOs[0].address;
        this.receiveID = res.data.cartOrder.customerAddressDTOs[0].id;
        if (res.data.shareFlag == 'Y') {
          this.houseAddress = '统仓地址'
          // this.distributors = true
          this.officeHis = true;
          this.dissize = true
          // 统仓收货地址
          this.orderDis = res.data.cartOrder.customerAddressDTOs;
          this.receive = res.data.cartOrder.customerAddressDTOs[0].address;
          this.receiveID = res.data.cartOrder.customerAddressDTOs[0].id;
          this.warehouseInfoId =  res.data.cartOrder.customerAddressDTOs[0].shareWarehouseId;
          // 海信办事处地址
          this.officehis = res.data.office;
          this.officeCode = res.data.office[0].code;
          this.receivehis = res.data.office[0].name;
          // 当收货地址为统仓时调用接口拿出统仓接口数据 获取对应仓库联系人
          let data = {
            sendToId: res.data.cartOrder.customerAddressDTOs[0].id
          }
          orderAddress(data).then(res => {
            this.contacts = res.data.contact;
            this.phoneCon = res.data.phone;
            this.changePhoneNum()
            this.TCInt = true;
          }).catch(err => {
            console.log(err);
          })
        } else {
          // 当用户选择直配到分销商时
          // 获取分销商列表
          if (res.data.cartOrder.custMarketModelDtos) {
            this.DisList = res.data.cartOrder.custMarketModelDtos;
            this.distrName = this.DisList[0].customerInfoName
          }

          // 分销商地址
          this.distributionAdd.DISADD = res.data.cartOrder.customerAddressDTOs[0].address;

          // 请求分销商 地址参数 orgId
          this.DISADDPARAMS.orgId = res.data.cartOrder.orgId;
          this.DISADDPARAMS.matklId = res.data.cartOrder.matklId;
          this.DISADDPARAMS.customerId = res.data.cartOrder.customerAddressDTOs[0].customerInfoId;
          if (res.data.cartOrder.marketAddress) {
            // 获取分销商地址
            this.choseDistribution = res.data.cartOrder.marketAddress
            // 分销商地址id
            this.distributionAdd.DISCODE = res.data.cartOrder.marketAddress.length>0?res.data.cartOrder.marketAddress[0].id:'';
            this.distrAddress = res.data.cartOrder.marketAddress.length>0?res.data.cartOrder.marketAddress[0].address:'';
          }
        }
        // 预付比例
        this.advancePayRate = res.data.cartOrder.advancePayRate
        // 是否可以使用返利
        this.tradeType = res.data.cartOrder.tradeType == '01' ? true : false;
        //  要求到货周
        getWeek({
          orgId: res.data.cartOrder.orgId,
          matklId: res.data.cartOrder.matklId,
          purchaseType: res.data.cartOrder.purchaseType
        }).then(res => {
          this.arriveOptions = res.data.list;
          this.selected = res.data.list[0].name;
          this.endTime = res.data.list[0].code;
          this.getOrderMoney(res.data.list[0].name)
        })
        // this.suppilers = res.data
        // 点击提交按钮提交参数
        this.orgAndGroup = res.data.cartOrder.orgAndGroup
        this.maxEndDate = res.data.cartOrder.maxDate
        // end 点击提交按钮参数
        this.obj = res.data;

        // for (let a = 0; a < res.data.cartOrder.deliveryList.length; a++) {
        //   if (res.data.cartOrder.deliveryList[a].id == res.data.cartOrder.defaultDelivery) {
        //     this.psfs = a;
        //     // 配送方式id
        //     this.trans = res.data.cartOrder.deliveryList[a].id;
        //   }
        // }
        if (res.data.cartOrder.defaultDelivery == 502004) {
          this.userDetailAddress = res.data.cartOrder.rectionAddress;
          this.userAdderss = true;
        }
        // 开票户头
        this.billToDTOs = res.data.cartOrder.billToDTOs;
        // 开票户头ID 及 公司名称
        if (res.data.cartOrder.billToDTOs.length > 0) {
          this.invoice = this.billToDTOs[0].drawerName;
          this.invoiceId = this.billToDTOs[0].id;
        }
        // this.choseCity(res.data.cartOrder.defaultProvice)
        // 收货地址
        this.orderDis = res.data.cartOrder.customerAddressDTOs;
        this.receive = res.data.cartOrder.customerAddressDTOs[0].address;
        this.warehouseInfoId =  res.data.cartOrder.customerAddressDTOs[0].shareWarehouseId;

        //  初始化获取手机号
        getPhone({
          sendToId: res.data.cartOrder.customerAddressDTOs[0].id
        }).then(res => {
          this.contacts = res.data.contact == '无' ? '' : res.data.contact;
          this.phoneCon = res.data.phone == '无' ? '' : res.data.phone;
          this.changePhoneNum()
        })
        // 获取商品列表list
        // 参数
        var goodsData = {
          code: '',
          orgCode: this.orgRange,
          orgId: res.data.cartOrder.orgId
        }
        this.proIdAgin = ''
        // 商品行数据
        // for (let key in res.data.cartDTOs) 
        res.data.cartDTOs.forEach(item => {
          // var aList = res.data.cartOrder.cartMap[key]
          item.isChooseNum = 0
          if (item.price != null) {
            item.oldPrice = (item.price).toFixed(2)
          }

          item.openPoliciesFlag = false
          item.itemZhengList = []
          item.maxNumberBegin = '99999999'

          item.oldfixedDiscount = item.fixedDiscount
          item.oldpriceGroupName = item.priceGroupName
          item.oldmakeUpTypeName = item.makeUpTypeName

          for (let key in res.data.policyMap) {
            if (key == item.productId) {
              item.itemZhengList = res.data.policyMap[key]
              item.pliceNameFull = item.policyName
            }
          }

          // item.policyId = '13964869334'
          if (item.policyId) {
            item.itemZhengList.forEach(zhengItem => {
              if (item.policyId == zhengItem.id) {
                zhengItem.ischerk = true
              }
            })
          }
          // res.data.policyMap.forEach((zhengItem,zhengIndex)=>{
          //   if(zhengIndex == item.productId){
          //     item.itemZhengList = zhengItem
          //   }
          // })
          var aList = res.data.cartDTOs
          this.GoodsList = aList;
          this.suppilers = aList[0].orgName + '-' + aList[0].materialGroupName;
          var rebate = 0;
          for (let i = 0; i < this.GoodsList.length; i++) {
            let paramDmsStock = {
              productCodes: [this.GoodsList[i].productId],
              supplierCode: ''
            };
            getChannelStockLIST(paramDmsStock)
              .then(stocksRes => {
                if(stocksRes.data && JSON.parse(stocksRes.data).data.length > 0) {
                  this.$set(this.GoodsList[i],'dmsKC',Number(JSON.parse(stocksRes.data).data[0].gicInvQty) + Number(JSON.parse(stocksRes.data).data[0].invQty))
                }
                this.$forceUpdate();
                // }
              })
              .catch(error => {
                console.log(error);
              });

            // 购物车id
            // this.orderCartIds
            // 商品数量 体积 价格;
            this.goodsNum = this.goodsNum + this.GoodsList[i].quantity;
            goodsData.code = aList[i].productId;
            this.proIdAgin += aList[i].productId + ','
            // 获取商品列表库存数 getNumber
            this.stock(goodsData, i);
            this.goodsVolume = (Number(this.goodsVolume) + Number(this.GoodsList[i].loadVolume) * Number(this.GoodsList[i].quantity)).toFixed(3);
            // var newItem = Object.assign(this.GoodsList[i], res.data[i]);
            // this.GoodsList[i] = newItem;
            this.goodsPrice = (this.goodsPrice + this.GoodsList[i].price * this.GoodsList[i].quantity).toFixed(2);
            // 预付款
            // this.advancePrice = this.advancePrice + this.GoodsList[i].advancePrice * this.
            rebate += this.GoodsList[i].price * this.GoodsList[i].quantity * (this.GoodsList[i].rebate / 100);
          }
          this.totalRebate = rebate < this.realData.cartOrder.balanceDTO.rebate ? this.realData.cartOrder.balanceDTO.rebate : rebate
        })
        // 支付方式数据
        // this.TotalPayment = res.data.cartOrder.balanceDTO;
        // this.totalAdvanceMoney = res.data.cartOrder.balanceDTO.totalAdvanceMoney;
        this.isYuMoney = res.data.cartOrder.isPujie
        this.TotalPayment = res.data.cartOrder.balanceDTO;
        this.totalAdvanceMoney = res.data.cartOrder.balanceDTO.totalAdvanceMoney;

        if (!this.isYuMoney) {
          this.totalAdvanceMoney = ((this.goodsPrice * res.data.cartOrder.advancePayRate) / 100).toFixed(2)
        }

        this.rebate = res.data.cartOrder.balanceDTO.rebate == -1 ? '0' : res.data.cartOrder.balanceDTO.rebate;
        this.getsaleShopList()
        this.getService()
        this.getPS()
        if(this.orderDis.length > 0) {
          let obj = this.orderDis.find((i)=>{//listData就是上面的数据源
            return i.id === res.data.cartOrder.customerAddressDTOs[0].id;//筛选出匹配数据
          });
          // 如果选择的地址状态为D 说明地址失效 弹出提示
          // if(obj.regionStatus == 'D') {
          //   this.$message.warning('由于行政区划调整，请您及时更新您的收货地址信息',5)
          //   this.receiveID = ''
          //   this.receive = ''
          //   this.warehouseInfoId = ''
          //   return
          // }
        }

      }).catch(err => {
        this.pageLoadFlag = false
        console.log(err)
      })
    },
    // 子组件选择商品
    // 查询库存数
    stock(goodsData, i) {
      getNumber({
        code: goodsData.code,
        orgId: goodsData.orgId
      }).then(resD => {
        var newObject = Object.assign(this.GoodsList[i], resD.data[i])
        this.GoodsList[i] = newObject;
      })
    },


    onAddressChange(addressValArr) {
      // 长度如果等于2 乡镇就置空
      if(addressValArr&&addressValArr.length == 2) {
        this.districtCode = '';
      }
      // 长度如果等于3 区县就置空
      if(addressValArr&&addressValArr.length == 3) {
        this.townCode = '';
      }
      if(addressValArr.length > 0) {
        addressValArr.forEach((item, index) => {
          if (index == 0) {
            this.provinceCode = item;
          }
          if (index == 1) {
            this.cityCode = item;
          }
          if (index == 2) {
            this.districtCode = item;
          }
          if (index == 3) {
            this.townCode = item;
            if((this.trans=='502004' || this.trans == '502008') && this.haveDeliveryFee){
              this.getFWF()
            }
          }
        });
      } else {
        this.provinceCode = ''
        this.cityCode = ''
        this.districtCode = ''
        this.townCode = ''
        this.arrivalTimeStr =  ''
        this.startShowTime =  ''
      }

      // ['340000000000', '340600000000', '340603000000', '340603400000', __ob__: Observer]
      // ..
    },
    // 城市选择
    handleCityChange() {
      this.districtCode = '';
      this.townCode = '';
      this.getTowns(this.cityCode)
    },
    // 城镇选择
    handleTownChange() {
      this.townCode = '';
      this.getqu(this.districtCode);
    },
    // 城镇下一级 选择
    handleZhenChange() {
    },
    showModal() {
      this.visible = true;
    },
    handleOk() {
      this.visible = false;
    },
    moreBill() {

    },
    onGiveType(e) {
      // this.trans = this.options[e.target.value].id;
      this.trans = e.target.value
      this.arrivalTimeStr =  ''
      this.startShowTime =  ''
      let obj = this.options.find((i) => {
        return i.id === this.trans;//筛选出匹配数据
      });
      this.haveDeliveryFee = obj&&obj.haveDeliveryFee == 1? true : false

      // 修改判断如果选择选项为直配到用户
      if( this.trans == '502004' ) {
        this.distributors = false;
        this.userAdderss = true;
        this.getPeople(this.receiveID)
      } else if (this.trans == 502008) {
        this.distributors = false;
        this.userAdderss = true;
      } else if (this.trans == 502005) {

        if (!this.DisList || this.DisList.length == 0) {
          this.$warning({
            content: '暂无可选分销商，请选择其他配送方式',
          });
          return;
        }
        this.distributors = true;
        this.userAdderss = false
        // // 放在点击经销商的地方
        // this.distrAddress = this.choseDistribution[0].value;
        // 分销商 分销地址
        this.getDISADDList();
        // this.showAnotheer()
      } else {
        this.distributors = false;
        this.userAdderss = false;
        this.getPeople(this.receiveID);
      }
      // }
    },
    // 分销商 分销地址 获取手机号
    showAnotheer() {
      getPhone({
        sendToId: this.distributionAdd.DISCODE
      }).then(res => {
        if (res.data.contact && res.data.phone) {
          this.distributors = true;
          this.contacts = res.data.contact;
          this.phoneCon = res.data.phone;
          this.changePhoneNum()
        } else {
          this.distributors = false
          this.$warning({
            content: '暂无可选分销商，请选择其他配送方式',
          });
        }

      })
    },
    onSubmit() { //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 截止时间选择
    endTimeChange(value) {
      this.endTime = value;
    },
    // 点击选择开票户头
    // choseAccount() {
    //   // axios请求后台开票户头
    //   this.accountShow = !this.accountShow;
    // },
    // 开票户头相关函数
    handleChange(value, e) {
      this.invoiceId = e.data.key
      this.invoice = value
      this.addressopenbill = false;

    },
    handleBlur() {
      this.receiveBill = !this.receiveBill;
    },
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 用户级联选择改变
    cityChange(value) {

    },
    // 直送用户/送装一体地区校验
    async deliveryAddressCheck() {
      const orgparamsList = this.GoodsList.map(el => {
        return {
          orgId: this.orgId,
          matklId: el.materialGroup
        }
      })
      let data = {
        "serviceCode":"cisDeliveryAddressCheck",
        "provinceCode":this.provinceCode,
        "cityCode":this.cityCode,
        "districtCode":this.districtCode,
        "townCode":this.townCode,
        "custId":this.$store.state.user.userInfo.customer.id,
        orgparamsList:orgparamsList,
        orderType:1,
        deliveryMethod:this.trans=='502004'?'04':'08',
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
      }
      return ctsApi(data)
    },
    getAddress(e) {
      console.log('eeeee',e)
      this.newTown = e[3]
      console.log('this.newTown ',this.newTown )
    },
    async isCheckShipTo(){
      let res = await checkShipTo({shipToId:this.receiveID })
      console.log('res',res)
      return res.data.data.isInvalidRegion
    },
    // 点击提交订单按钮
    async  orderSubmit() {
      if(['502001', '502002'].includes(this.trans) && this.houseAddress == '仓库地址'&& !this.newTown) {
        let checkRes= await this.isCheckShipTo()
        if(checkRes == 1) {
          // 上面不符合条件的弹出弹窗
          this.visibleAddress = true
          return
        }
        console.log('isInvalive',checkRes)
      }
      if(['502004', '502008'].includes(this.trans)) {
        // 判断下单产品是否支持直送用户
        const index1 = this.options.findIndex(el => el.code == '04')
        if(['502004'].includes(this.trans) && this.options[index1].msg) {
          this.$message.warning(this.options[index1].msg)
          return false
        }
        // 判断下单产品是否支持送装一体
        const index2 = this.options.findIndex(el => el.code == '08')
        if(['502008'].includes(this.trans) && this.options[index2].msg) {
          this.$message.warning(this.options[index2].msg)
          return false
        }
        if(!this.townCode) {
          this.$message.warning('请选择收货地区')
          return false
        }
        // const res =  await this.deliveryAddressCheck()
        // if(res.data.code == 1) {
        //   this.$message.warning(res.data.msg)
        //   return;
        // }
        if(this.isShowBtn){
          const res =  await this.deliveryAddressCheck()
          if(res.data.code == 1) {
            this.$message.warning(res.data.msg)
            return;
          }
        }
        if(this.userDetailAddress == '' ){
          this.$message.warning('请填写详细地址');
          this.isLoading = false
          return;
        }
        if(!this.salesShopInfoId&& this.isShowBtn) {
          this.$message.warning('请选择销量所属门店')
          return false
        }
        if(this.TipsIds.length == 0&& this.isShowBtn) {
          this.$message.warning('请选择用户标签')
          return false
        }
      }
      if( (this.trans == '502004' ||this.trans== '502008') && this.RouterParams.purchaseType==1 && this.isShowBtn) {
        // 判断零售价格在建议零售价0.5倍到1倍之间。
        let arr = this.GoodsList
        for(let i = 0; i < arr.length; i++){
          if (!arr[i].salePrice) {
            this.$message.warning(`请输入第${i+1}件商品的零售价格`)
            return false
          }
          if ( Number(arr[i].retailPrice) && (( Number(arr[i].salePrice) <  Number(arr[i].retailPrice * 0.5) ) || (  Number(arr[i].salePrice) >  Number(arr[i].retailPrice * 1) ))){
            this.$message.warning(`建议零售价为${arr[i].retailPrice}元，可填范围在0.5倍到1倍之间。`)
            return false
          }
        }
      }

      let chooseNumAll = ''
      this.GoodsList.forEach(item => {
        chooseNumAll += item.quantity + ','
      })
      chooseNumAll = chooseNumAll.substring(0, chooseNumAll.length - 1)
      if (this.selected == '') {
        this.$message.warning('请填写时效信息');
        this.isLoading = false
        return;
      }
      if (this.userAdderss) {
        if(!(this.districtCode && this.townCode)){
          this.$message.warning('请选择直配地址');
          this.isLoading = false
          return;
        }
        if(this.userDetailAddress == '' ){
          this.$message.warning('请填写详细直配地址');
          this.isLoading = false
          return;
        }
      }
      if (!this.contacts) {
        this.$message.warning('请填写联系人信息');
        this.isLoading = false
        return;
      }
      if (!this.phoneCon) {
        this.$message.warning('请填写手机号');
        this.isLoading = false
        return;
      }
      if (this.phoneCon.trim().length !== 11) {
        this.$message.warning("手机号不正确，请重新填写");
        return;
      }
      //  else if (!(/^1[3456789]\d{9}$/.test(this.phoneCon))) {
      //   this.$message.warning("手机号码有误，请重填");
      //   return;
      // }
      if (this.totalAdvanceMoney > this.TotalPayment.canUseMoney) {
        this.$message.warning('余额不足');
        this.isLoading = false
        return;
      }
      let saleId = ''
      if(this.trans == '502004'|| this.trans == '502008') {
        saleId = this.salesShopInfoId
      } else {
        saleId = ''
      }
      let versionsAll = ''
      this.GoodsList.forEach(items => {
        versionsAll += (items.policyId ? items.policyId : '') + ','
      })
      const orderCartList = this.GoodsList.map(el => {
        return {
          cartId: el.productId,
          retailPrice:el.salePrice
        }
      })
      versionsAll = versionsAll.substring(0, versionsAll.length - 1)
      let proIdAgin = this.proIdAgin.substring(0, this.proIdAgin.length - 1)
      let data = {
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
        orderCartList: this.RouterParams.purchaseType==1?JSON.stringify(orderCartList):[],
        purchaseType: this.RouterParams.purchaseType,
        weekName: this.selected,
        orgAndGroup: this.RouterParams.orgAndGroup,
        // orderCartIds: this.RouterParams.carts,
        orderId: this.$route.query.carts,
        products: proIdAgin,
        productNumbers: chooseNumAll,
        versions: versionsAll,
        // 是否使用返利
        // orderRebate: this.tradeType ? 'Y' : 'N',
        orderRebate: this.isTradeChecked ? 'Y' : 'N',
        billTo: this.invoiceId,
        address1: this.receiveID,
        trans: this.trans,
        // district: this.userAdderss ? this.proObj.cityCode : '',
        // town: this.userAdderss ? this.proObj.zCode : '',
        // 用户手填地址
        address3: this.userDetailAddress,
        arrivalTimeStr:this.arrivalTimeStr&&this.haveDeliveryFee?this.arrivalTimeStr.replace(/\//g,"-")+' 23:59:59':'',
        // 分销商地址  id
        address4: this.distributors ? this.distributionAdd.DISCODE : '',
        contact: this.contacts,
        mobile: this.phoneCon,
        orderCode: this.orderNumber,
        maxEndDate: this.maxEndDate,
        endDate: this.endTime,
        officeId: this.officeCode,
        // 是否允许提前发货
        isAllowAdvancdeliver: this.allowAdvance ? 1 : 0,
        salesShopInfoId: saleId,
        // 服务列表
         serviceTypeCodes:this.fwlbId.join(','),
         district: this.userAdderss ? this.districtCode : '',
         town: this.userAdderss ? this.townCode : '',
        gender:(this.trans != '502004'&&this.trans != '502008') ? '' : this.sexId,// 性别
        sourceId:(this.trans != '502004'&&this.trans != '502008')?'':this.SorceIds,// 用户来源
        labelList:(this.trans != '502004'&&this.trans != '502008')?'':this.TipsNames.join(','), // 用户标签
        validTown:(this.trans == '502001'||this.trans == '502002')&&this.houseAddress=='仓库地址'?this.newTown:''
      }
      this.isLoading = true
      againOrderSubmit(data).then(res => {
        this.isLoading = false
        if (res.data.code == 1) {
          this.$message.warning(res.data.msg);
          this.isLoading = false;
          return
        }
        // 判断是否有提示参数  有的话弹出弹窗
        if(res.data.frontMsg) {
          // 弹出提示信息
          this.mainMsg =  res.data.frontMsg.msgDesc
          this.orgMsg = res.data.frontMsg.tip
          this.onluyOneMessage = true
          return
        }
        if(res.data.error) {
          this.$message.warning( res.data.error)
          return
        }

        if (res.data.b2bOrderCode) {
          if(res.data.serviceFeeCode) {
            this.$router.push({
              path: "/serviceFeeModule/pay",
              query: {
                id:res.data.serviceFeeCode,
                batch:0,
                b2bOrderCode: res.data.b2bOrderCode,
                orgName: res.data.orgName
              }
            });
            return
          }
          this.$router.push({
            path: '/confirm/confirmsuccess',
            query: {
              b2bOrderCode: res.data.b2bOrderCode,
              orgName: res.data.orgName
            }
          })
        }
      }).catch(error => {
        this.pageLoadFlag = false
        console.log(error);
      })
    },
    // 获取子组件选中商品
    sonCheckList(data) {
      let cheacked = this.sonChectItme.indexOf(data)
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1)
      }
    },
    //   点击返回购物车
    goCar() {
      this.$router.push("/cart");
    },
    // 开票户头
    receivebill() {
      this.receiveBill = !this.receiveBill;
      this.receiveopen = true;
    },
    // 收货地址点击选择
    receiveAddress() {
      this.receiveAdd = !this.receiveAdd;
      this.addressopenbill = true;
    },
    // 收货地址下拉框选择
    handleChangeAddress(value, e) {
      // 筛选出来当前选择的数据
      let obj = this.orderDis.find((i)=>{//listData就是上面的数据源
        return i.id === e.key;//筛选出匹配数据
      });
      // 如果选择的地址状态为D 说明地址失效 弹出提示
      // if(obj.regionStatus == 'D') {
      //   this.$message.warning('由于行政区划调整，请您及时更新您的收货地址信息',5)
      //   this.receiveID = ''
      //   this.receive = ''
      //   this.warehouseInfoId = ''
      //   return
      // }
      this.receiveAdd = !this.receiveAdd;
      this.addressopenbill = false;
      this.addressopen = false;

      this.receive = value;
      // 收货地址 选中 id
      this.receiveID = e.key;
      this.warehouseInfoId = obj.shareWarehouseId;
      // this.SAddressChange(e.data.key)
      // 获取手机号
      getPhone({
        sendToId: e.key
      }).then(res => {
        this.contacts = res.data.contact == '无' ? '' : res.data.contact;
        this.phoneCon = res.data.phone == '无' ? '' : res.data.phone;
        this.changePhoneNum()
      })
      if((this.trans=='502004' || this.trans == '502008') && this.haveDeliveryFee){
        this.getFWF()
      }
    },
    // 海信办事处
    hishandleChangeAddress(value, e) {
      this.hisenseOff = !this.hisenseOff;
      this.officeCode = e.data.key;
      this.receivehis = value;
      this.openoffice = false;
    },
    // 海信办事处
    hisenseOffice() {
      this.hisenseOff = !this.hisenseOff;
      this.openoffice = true;
    },
    // 切换收货地址
    // SAddressChange(id) {
    //   showAnother({
    //     id
    //   }).then(res => {
    //     this.contacts = res.data.contact;
    //     this.phoneCon = res.data.phone;
    //   }).catch(err => {
    //   })
    // },
    // 分销商显示隐藏
    choseDistr() {
      this.distr = !this.distr;
    },
    // 切换分销商
    handleChangeDistr(value, e) {
      this.distrName = value;
      this.DISADDPARAMS.customerId = e.key;
      this.distr = false;
      this.getDISADDList();
    },
    // 分销商切换获取分销商地址list
    getDISADDList() {
      let data = this.DISADDPARAMS;
      getDISAdd(data).then(res => {

        this.choseDistribution = res.data
        this.distributionAdd.DISCODE = res.data[0].id
      }).catch(err => {
        console.log(err);
      })
    },
    distrAddShow() {
      this.distrAdd = !this.distrAdd;
    },
    // 选择经销商地址
    handleChangeDistrAdd(value, e) {
      this.distributionAdd.DISCODE = e.key;
      this.distributionAdd.DISADD = value;
      this.distrAddress = value;
      this.distrAdd = false;
      getPhone({
        sendToId: e.key
      }).then(res => {
        this.contacts = res.data.contact;
        this.phoneCon = res.data.phone;
        this.changePhoneNum()
      })
    },
    // 分销商地址切换获取联系人
    // getPeople() {
    //   getPhone()
    // },
    getPeople(sendToId) {
      getPhone({
        sendToId
      }).then(res => {
        this.contacts = res.data.contact == "无" ? '' : res.data.contact;
        this.phoneCon = res.data.phone == "无" ? '' : res.data.phone;
        this.changePhoneNum()
      })
    },
    addItem() {

      //this.items.push(`New item ${index++}`);
    },
    // 监听输入变化
    // handleChangeInt(e) {

    // },
    // 弹框
    ToDetail() {
      this.modelShow = true;
      this.pageLoadFlagTable = true;
      var data = {
        orgIdParam: this.orgId,
        matklIdParam: this.matklId,
        weekName: this.selected,
        purchaseType: this.RouterParams.purchaseType
      }
      quotaDeatail(data).then(res => {
        this.pageLoadFlagTable = false;
        this.pageLoadFlagTable = false;
        this.dataAll = res.data.dataList;
        this.data = res.data.dataList.slice(0, 10)
        this.pager.count = res.data.dataList.length;
      })
    },
    // 分页
    pagesiezes(data) {
      // var prev = data - 1;
      var start = (data - 1) * this.pager.pageSize;
      this.data = this.dataAll.slice(start, start + this.pager.pageSize)
      this.pager.pageSize;
    },
    handleWeek(value, e) {
      this.endTime = e.key;
      this.selected = value
      this.getOrderMoney(value)
    },
    // ordermoney
    getOrderMoney(weekName) {
      let datazzc = {
        orgId: this.orgId,
        matkl: this.matklId,
        weekName
      }
      if (this.realData.cartOrder.purchaseType == '2' && this.realData.cartOrder.pujie) {
        moneyWeek(datazzc).then(res => {
          // this.TotalPayment= res.data.balance;
          this.TotalPayment.balanceAccount = res.data.balance.balanceAccount;
          this.TotalPayment.waitMoney = res.data.balance.waitMoney;
          this.TotalPayment.canUseMoney = this.$util.getFloat((this.TotalPayment.balanceAccount - this.TotalPayment.waitMoney), 2);
        })
      }
    },
    // 智能识别
    recognitionFun(res){
      const townCode = res.levelAddress.town_code;
      // 收货地址
      this.addressHx = [townCode.slice(0,2).padEnd(12, 0),townCode.slice(0,4).padEnd(12, 0),townCode.slice(0,6).padEnd(12, 0),townCode.padEnd(12, 0)];
      // 收货地址 code
      this.provinceCode = townCode.slice(0,2).padEnd(12, 0);
      this.cityCode = townCode.slice(0,4).padEnd(12, 0);
      this.districtCode = townCode.slice(0,6).padEnd(12, 0);
      this.townCode = townCode.padEnd(12, 0);
      // 详细地址
      this.userDetailAddress = res.address;
      // 姓名
      this.contacts = res.userName;
      // 电话
      this.phoneCon = res.mobile;
    }
  }
}