<template>
  <div>
    <div class="product-wrap" v-for="(item,index) in datalist" :key="index">
      <div class="productTop">
        <div class="item1">
          <div class="item1Img">
            <div class="item1ImgC">
              <h-img
                :imgUrl="item.materialGroup + '/' + item.productCode + '/180-180/' + item.picture"
                :errorImg="'/assets/new/images/product/' +item.materialGroup + '.jpg'"
                :isUseBasePath="true"
              ></h-img>
            </div>
          </div>
          <div class="item1Val">
            <div class="item1Title">
              <p v-if="item.b2bName">
                <span class="freeShipping"  v-if="item.productLabel && item.productLabel.indexOf('15691143850') !== -1 ">免运费</span>
                <span class="bright"  v-if="item.productLabel && item.productLabel.indexOf('15691143849') !== -1 ">璀璨</span>
                {{item.b2bName}}</p>
            </div>
            <p>
              <span style="margin-right:12px;">品牌：海信</span>
              <span v-if="item.color">颜色：{{item.color}}</span>
            </p>
            <p v-if="item.loadVolume">体积：{{Number(item.loadVolume).toFixed(3)}}m³</p>
          </div>
        </div>
        <div class="item2">
          <div class="item2Title">
            ￥{{$util.getFloat(item.price,2)}}
            <span
              style="margin-left:5px;"
              v-if="item.standardPrice"
            >￥{{item.standardPrice}}</span>
          </div>
          <div class="item2Item">
            <p v-show="item.fixedDiscount!==null">固定折扣：{{item.fixedDiscount}}%</p>
            <p v-show="item.priceGroupName!==null">定价组：{{item.priceGroupName}}</p>
<!--            <p v-show="item.makeUpTypeName!==null">补差类型：{{item.makeUpTypeName}}</p>-->
            <!-- <p>单台返利30元</p> -->
          </div>
        </div>
        <div class="item3">
          <number-box
            @getNumber="dataChange($event,item.productId)"
            :curVal.sync="item.quantity"
            :minVal="1"
            :maxVal="item.maxNumberBegin"
          ></number-box>

          <!-- <div class="item3Title">×{{item.quantity}}</div> -->
          <div class="item3Item">
            <div class="item3Items">
              <p v-if="item.inventory">海信{{item.inventory}}</p>
              <p >我的库存{{item.dmsKC || 0}}</p>
<!--              <p v-show="item.sharedInv">共享{{item.sharedInv==null?0:item.sharedInv}}</p>-->
              <p style="color:#FF8F3C;" v-if="item.stockAgeNum">超库龄上限{{item.stockAgeNum}}台</p>
              <slot></slot>
            </div>
          </div>
        </div>
        <div class="item4" v-if="trans !='502004'&&trans!='502008'">
          <div class="item4Title">小计：￥{{$util.getFloat(item.price*item.quantity,2)}}</div>
        </div>
        <div class="item4" v-if="(trans=='502004'||trans=='502008') && !haveDeliveryFee">
          <div class="item4Title">小计：￥{{$util.getFloat(item.price*item.quantity,2)}}</div>
          <div class="purchase" style="margin-top:6px;line-height: 16px;font-size: 12px" v-if="isShowBtn">
            <div class="price">零售价格：￥
              <span :style="{color:Number(item.salePrice)>0?'#262626':'#777'} "
                    v-if="!idEdit">{{Number(item.salePrice) && Number(item.salePrice)>0?item.salePrice:'请输入'}}
              </span>
              <a-input oninput="this.value=this.value.match(/\d+\.?\d{0,2}/)" type="text" v-if="idEdit" @blur="blurPrice(item)" @change="changePrice(item,$event)" style="width: 120px" v-model="item.salePrice" />
            </div>
            <span @click="edit(item)" class="edit">{{idEdit?'完成':'编辑'}}</span>
          </div>
        </div>
        <div class="item4" v-if="(trans=='502004'||trans=='502008') && haveDeliveryFee">
          <div class="item4Title">小计：</div>
          <p style="margin-top:8px;line-height: 16px;font-size: 12px">货款:￥{{$util.getFloat(item.price*item.quantity,2)}}</p>
          <p style="margin-top:8px;line-height: 16px;font-size: 12px">服务费:￥{{item.serviceMoney?Number(item.serviceMoney).toFixed(2):'0.00'}}</p>
          <div class="purchase" style="margin-top:6px;line-height: 16px;font-size: 12px" >
            <div class="price">零售价格：￥
              <span :style="{color:Number(item.salePrice)>0?'#262626':'#777'} "
                    v-if="!idEdit">{{Number(item.salePrice) && Number(item.salePrice)>0?item.salePrice:'请输入'}}
              </span>
              <a-input oninput="this.value=this.value.match(/\d+\.?\d{0,2}/)" type="text" v-if="idEdit" @blur="blurPrice(item)" @change="changePrice(item,$event)" style="width: 120px" v-model="item.salePrice" />
            </div>
            <span @click="edit(item)" class="edit">{{idEdit?'完成':'编辑'}}</span>
          </div>

          <!-- 采购订单详情取消 -->
          <!-- <p class="purchase" @click="cancleClick(item)">取消</p> -->
        </div>
      </div>
      <div style="padding-bottom: 24px;" v-if="item.policyName">
        <div class="settlement">
          <div class="zhengce">
            <!-- 优惠政策 -->
            <div class="settlementVal">
              <p>{{item.policyName}} <span v-if="item.specialPriceVcode">[{{ item.specialPriceVcode }}]</span></p>
              <!--            <p>提货价：{{item.standardPrice}}</p>-->
              <p v-if="item.reduce">直扣：{{item.reduce}}元</p>
              <p v-if="item.discount">折扣：{{ $util.getFloat(item.discount,2) }}%</p>
              <p v-if="item.specialPrice">开票价：{{$util.getFloat(item.specialPrice,2) }}元</p>
              <p>定价组：{{item.priceGroupName}}</p>
              <p v-if="item.canQuantity">可购数量：{{item.canQuantity}}</p>
              <!--            <p>补差类型：{{item.makeUpTypeName}}</p>-->
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCust } from "../api.js";
// import { log } from 'util';
export default {
  name: "ProductItem",

  props: {
    datalist: {
      type: Array,
      default: () => []
    },
    settlementItem: {
      required: false
    },
    trans:{
      required: true
    },
    haveDeliveryFee:{
      required: true
    },
    isShowBtn:{
      required: true
    },
    // // 特购专区
    // settlement: {
    //   required: true,
    //   default: false
    // },
  },
  data() {
    return {
      makeShure: true,
      maxNumberBegin: "999999999",
      idEdit:false
    };
  },
  watch:{
    haveDeliveryFee: {
      handler: function(val, oldVal) {
      },
      // 深度观察监听
      deep: true
    },
    trans: {
      handler: function(val, oldVal) {
      },
      // 深度观察监听
      deep: true
    },
    item: {
      handler: function(val, oldVal) {},
      // 深度观察监听
      deep: true
    },
  },
  methods: {
    edit(item) {
      this.idEdit = !this.idEdit
    },
    changePrice(item,e){
      this.$emit("changePrice", item);
    },
    blurPrice(item,e){
      item.salePrice=item.salePrice.replace(/[^\-?\d.]/g,'')
      item.salePrice= Number(item.salePrice).toFixed(2)
      if (item.type == 1 && item.salePrice) {
        if (Number(item.salePrice) < item.min || Number(item.salePrice) > item.max) {
          if (item.haveRetailL) {
            this.$message.warning(`请在默认建议零售价的${item.bei}倍范围内录入价格`);
          } else {
            this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
          }
          item.salePrice = item.retailPrice;
          this.$forceUpdate()
          return;
        }
      }
      this.$emit("changePrice", item);
    },
    // 展开更多政策
    more(id) {
      this.datalist.forEach(goodItem => {
        if (goodItem.productId == id) {
          goodItem.openPoliciesFlag = !goodItem.openPoliciesFlag;
          this.$forceUpdate;
        }
      });
    },
    // 移除所选政策
    delectPlice(id) {
      this.datalist.forEach(goodItem => {
        goodItem.itemZhengList.forEach(pliceItem => {
          if (goodItem.productId == id) {
            pliceItem.ischerk = false;
            goodItem.pliceNameFull = "";
            goodItem.openPoliciesFlag = false;
            goodItem.price = (
              (goodItem.oldPrice * (100 - goodItem.fixedDiscount)) /
              100
            ).toFixed(2);
            goodItem.subTotal = goodItem.price * goodItem.quantity;
            goodItem.fixedDiscount = goodItem.oldfixedDiscount;
            goodItem.priceGroupName = goodItem.oldpriceGroupName;
            goodItem.makeUpTypeName = goodItem.oldmakeUpTypeName;
            this.$emit("moveVersion", id);
          }
        });
      });
    },
    // 点击政策触发
    clickBox(id, index, policyIndex, orgcode, orgId, productId) {
      let oldIndex = "";
      this.datalist[index].itemZhengList.forEach((pliceItem, index1) => {
        if (pliceItem.ischerk) {
          oldIndex = index1;
        }
        pliceItem.ischerk = false;
        if (pliceItem.id === id) {
          if (pliceItem.canQuantity >= this.datalist[index].quantity) {
            this.$set(
              this.datalist,
              index.maxNumberBegin,
              pliceItem.canQuantity
            );
            this.datalist[index].maxNumberBegin = pliceItem.canQuantity;
            this.datalist[index].policyId = id;
            pliceItem.ischerk = true;
            this.datalist[index].pliceNameFull = pliceItem.policyName;
            this.datalist[index].openPoliciesFlag = false;
            this.datalist[index].canQuantity = pliceItem.canQuantity;

            let params = {
              versionId: id,
              orgCode: orgcode,
              orgId: orgId,
              productId: productId
            };

            getCust(params)
              .then(res => {
                this.datalist[index].fixedDiscount = res.data.fixedDiscount;
                this.datalist[index].priceGroupName = res.data.pricingGroupName;
                this.datalist[index].makeUpTypeName = res.data.makeUpType;
                this.datalist[index].price = (
                  (this.datalist[index].itemZhengList[policyIndex].price *
                    (100 - res.data.fixedDiscount)) /
                  100
                ).toFixed(2);
                this.datalist[index].subTotal = (
                  this.datalist[index].price * this.datalist[index].quantity
                ).toFixed(2);
                this.$emit("totalPriceGet", productId, id);
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            this.datalist[index].itemZhengList[oldIndex].ischerk = true;
          }
        }
      });
    },
    dataChange($event, productId) {
      this.$emit("numberChange", $event, productId);
    },
    // changeOpened(item) {
    //   this.$set(item, "opened", !item.opened);
    //   // item.opened = !item.opened
    // },
    // // 点击取消按钮
    cancleClick(data) {
      this.$emit("cancleClick", data);
    },
    // 特购页面点击是否需要特购优惠
    handleChange(data) {
      this.makeShure = !this.makeShure;
      this.$emit("settleChose", data);
    },
    // 点击购物车
    carClick() {
      this.$emit("carClick", "");
    }
  }
};
</script>

<style scoped lang="less">
/deep/.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: fixed;
}
.product-wrap {
  width: 1140px;
  padding: 23px 0 0px 24px;
  margin: 20px auto 0;
  margin-top: 16px;
  box-sizing: border-box;
  text-align: left;
  width: 1140px;
  background: #F7F7F7;
  border-radius: 8px;
  min-height: 144px;
  .productTop {
    display: flex;
    justify-content: flex-start;
    .item1 {
      width: 392px;
      height: 100px;
      display: flex;
      justify-content: flex-start;
      .item1Img {
        margin-right: 16px;
        width: 100px;
        height: 100px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        .item1ImgC {
          width: 80px;
          height: 80px;
        }
      }
      .item1Val {
        text-align: left;
        padding-top: 14px;
        box-sizing: border-box;
        .item1Title {
          display: flex;
          justify-items: flex-start;
          align-items: center;
          .freeShipping {
            border: none;
            font-size: 12px;
            color: #fff;
            // width: 42px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
            border-radius: 2px;
            padding:0 2px;
            font-weight: 400;
            margin-right: 4px;
          }
          .bright {
            font-size: 12px;
            color: #433538;
            width: 30px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
            border-radius: 2px;
            font-weight: 400;
          }
          p {
            font-size: 14px;
            color: #262626;
            line-height: 20px;
            margin-top: 0;
            font-weight: 500;
          }
          .buyCar {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background: #ecf8f8;
            text-align: center;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-top: 6px;
            }
          }
        }
        p {
          margin-top: 8px;
          font-size: 12px;
          color: #777777;
          line-height: 16px;
        }
      }
    }
    .item2 {
      width: 210px;
      //margin-left: 60px;
      .item2Title {
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
        color: #262626;
        line-height: 20px;
        padding-top: 14px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 500;
        color: #262626;
        span {
          text-decoration: line-through;
          font-size: 12px;
          color: #777777;
        }
      }
      .item2Item {
        font-size: 12px;
        color: #777777;
        line-height: 16px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        p {
          margin-right: 12px;
          margin-top: 8px;
        }
      }
    }
    .item3 {
      width: 210px;
      margin-left: 24px;
      margin-top:14px;
      .number-box {
        margin-left:-60px!important;
      }
      .item3Title {
        padding-top: 14px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 500;
        color: #262626;
      }
      .item3Item {
        font-size: 12px;
        color: #777777;
        line-height: 16px;
        margin-top:5px;

        p {
          margin-top: 8px;
        }
        .item3Items {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          p {
            margin-right: 10px;
          }
        }
      }
    }
    .item4 {
      width: 264px;
      padding-top: 14px;
      box-sizing: border-box;
      //margin-left: 24px;
      .item4Title {
        font-size: 14px;
        font-weight: 500;
        color: #262626;
      }
      .purchase {
        //cursor: pointer;
        //margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        display: flex;
        flex-direction: row;
        align-items: center;
        .price{
          display: flex;
          flex-direction:row;
          align-items: center;
        }
        .edit {
          cursor: pointer;
          color: #1890FF;
          margin-left: 30px;
        }
      }
    }
  }

  .line {
    width: 1092px;
    height: 1px;
    background: #eeeeee;
    // border: 1px solid #eeeeee;
    margin-top: 22px;
  }
  .settlement {
    //width: 100%;
    ////height: 100px;
    //display: flex;
    //justify-content: flex-start;
    ////align-items: center;
    //padding-left: 30px;
    //box-sizing: border-box;
    width: 1092px;
    height: 32px;
    background: #ECF8F8;
    border-radius: 5px;
    border: 1px solid rgba(0,170,166,0.24);
    margin-top: 12px;

    .zhengce {
      width: 100%;
      //height: 96px;
      border-radius: 4px;
      // border: 1px solid #fd6a6e;
      //margin-top: 12px;
      margin-bottom: 0;
      // background-image: url(/img/right.ab515ecb.png);
      // background-position: right bottom;
      // background-size: 26px 19px;
      // background-repeat: no-repeat;
      .settleTitle {
        font-size: 14px;
        color: #777777;
        line-height: 16px;
        margin-left: 24px;
        margin-bottom: 8px;
        margin-top: 16px;
      }
      .settlementVal {
        // width: 800px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-left: 24px;
        margin-bottom: 24px;
        p {
          width: 120px;
          text-align: left;
          font-size: 12px;
          color: #777;
          line-height: 16px;
          margin-right: 20px;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>